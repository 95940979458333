import React from 'react';
import './styles/supportContentLinks.css';

const SupportContentLinks = () => {
  return (
    <div className="content-links">
      <div className="link-content">
        <div className="manual-icon" />
        <a className="link" target="_blank" href="https://beon.ly/ajuda">
          Visite o Blog de Sucesso!
        </a>
      </div>

      <div className="link-content">
        <div className="support-icon" />
        <a className="link" target="_blank" href="https://beon.movidesk.com">
          Acesse o Service Desk
        </a>
      </div>
    </div>
  );
};

export default SupportContentLinks;
