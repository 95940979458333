import React, { useState } from 'react';
import { Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import SupportContentLinks from './SupportContentLinks';
import './styles/supportMenu.css';

const SupportMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const ClosedContent = () => {
    return (
      <div className="support-button" onClick={onButtonClick}>
        <div className="beon-logo" />
        <Divider type="vertical" style={{ background: 'rgb(0, 0, 0)' }} />
        <p className="beon-text">Suporte</p>
      </div>
    );
  };

  const OpenedContent = () => {
    return (
      <div className="content">
        <div className="content-header">
          <div className="beon-logo" />
          <p className="expanded-title">Preciso de Ajuda</p>
          <div className="x-button" onClick={onButtonClick}>
            <CloseOutlined className="x-icon" />
          </div>
        </div>

        <SupportContentLinks />
      </div>
    );
  };

  return (
    <div
      className={`container ${
        isOpen ? 'container-opened' : 'container-closed'
      }`}
    >
      {isOpen ? <OpenedContent /> : <ClosedContent />}
    </div>
  );
};

export default SupportMenu;
